import type { PlanDetails } from './types.js';
import { PlanNames } from "@quickbyte/common";

export const plans: PlanDetails[] = [
    {
        displayName: 'Free',
        currency: 'KES',
        annualPrice: 0,
        monthlyPrice: 0,
        monthlyPlanName: PlanNames.free,
        annualPlanName: PlanNames.free,
        features: [
            '3GB total storage',
            '1 team member',
            'Up to 2 projects',
            'Send up to 3GB per transfer',
            '7-day transfer expiry',
            'Streaming playback',
            'Version management',
            'Frame-by-frame comments',
            'Folder sync (coming soon)'
            
        ]
    },
    {
        displayName: 'Early Bird Starter',
        currency: 'KES',
        annualPrice: 9_000,
        monthlyPrice: 1_000,
        monthlyPlanName: PlanNames.earlyBirdStarterMonthly,
        annualPlanName: PlanNames.earlyBirdStarterAnnual,
        features: [
            '1TB total storage',
            'Up to 2 members',
            'Unlimited Projects',
            'Send up to 200GB per transfer',
            '30-day transfer expiry',
            'Streaming playback',
            'Version management',
            'Frame-by-frame comments',
            'Folder sync (coming soon)'
        ]
    },
    {
        displayName: 'Early Bird Pro',
        currency: 'KES',
        annualPrice: 38_000,
        monthlyPrice: 4_000,
        monthlyPlanName: PlanNames.earlyBirdProMonthly,
        annualPlanName: PlanNames.earlyBirdProAnnual,
        features: [
            '5TB total storage',
            'Up to 6 members',
            'Unlimited Projects',
            'Send up to 200GB per transfer',
            'Unlimited transfer expiry',
            'Streaming playback',
            'Version management',
            'Frame-by-frame comments',
            'Folder sync (coming soon)'
        ]
    },
    {
        displayName: 'Early Bird Business',
        currency: 'KES',
        annualPrice: 192_000,
        monthlyPrice: 20_000,
        monthlyPlanName: PlanNames.earlyBirdBusinessMonthly,
        annualPlanName: PlanNames.earlyBirdBusinessAnnual,
        features: [
            '25TB total storage',
            'Unlimited members',
            'Unlimited Projects',
            'Send up to 200GB per transfer',
            'Unlimited transfer expiry',
            'Streaming playback',
            'Version management',
            'Frame-by-frame comments',
            'Folder sync (coming soon)'
        ]
    },
]

export const FREE_TIER = 'freeTier';
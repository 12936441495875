<template>
  <div class="flex flex-1 flex-col items-center overflow-y-auto">
    <div class="flex justify-center mb-2">
      <div class="text-lg flex gap-2 items-center">
        <span>Monthly</span>
        <input type="checkbox" v-model="isAnnual" class="toggle toggle-xs toggle-primary" checked />
        <span>Annually <span class="text-xs">(20% off)</span></span>
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 overflow-y-auto">
      <!-- <div  v-for="plan in plans" :key="plan.displayName" class=""> -->
        <PlanCard
          v-for="plan in plans"
          :key="plan.displayName"
          :plan="plan"
          :annual="isAnnual"
          :account="account"
          @transaction="$emit('transaction', $event)"
        />
      <!-- </div> -->
    </div>
    <div class="mt-2 text-xs">
      Need more? Reach out to us at <a href="mailto:support@quickbyte.io" class="underline">support@quickbyte.io</a>.
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed } from "vue";
import type { AccountWithSubscription } from "@quickbyte/common";
import type { VerifyTransansactionResult } from "@/core";
import { plans, FREE_TIER } from "./plans";
import PlanCard from "./PlanCard.vue";

const props = defineProps<{
  account: AccountWithSubscription
}>();

defineEmits<{
  (e: 'transaction', transaction: VerifyTransansactionResult): void;
}>();

const isAnnual = ref(true);
</script>